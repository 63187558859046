.active {
    background-color: darkviolet;
    /* Background color */
    border-radius: 20px;
    /* Rounded corners */
    padding: 8px 20px !important;
    /* Padding for spacing */
    color: #fff !important;
    /* Text color */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* Shadow effect */
    transition: background-color 0.2s ease, transform 0.3s ease, box-shadow 0.3s ease;
    /* Smooth transitions */
}

.navbar-collapse ul li:hover a {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0);
    color: #43f607 !important;
    /* font-weight: bold; */
}

@keyframes waveAnimation {
    0% {
        background-position: 0 0;
    }

    25% {
        background-position: 100% 0;
    }

    50% {
        background-position: 100% 100%;
    }

    75% {
        background-position: 0 100%;
    }

    100% {
        background-position: 0 0;
    }
}

#interships .card {
    background: linear-gradient(to right, #FF0076, #590FB7, #9575cd, #CB5EEE, #FFCAC9);
    background-size: 800% auto;
    animation: waveAnimation 10s linear infinite alternate;
    box-shadow: 0 2px 4px rgba(12, 244, 236, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}



#interships .card:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 8px rgba(12, 244, 236, 0.932);
}

#interships .card:hover img {
    animation: scaleTranslate 2s infinite alternate;
}

@keyframes scaleTranslate {
    0% {
        transform: scale(1) translate(0, 0);
    }

    50% {
        transform: scale(1.1) translate(-2px, 0);
    }

    100% {
        transform: scale(1) translate(0, 0);
    }
}


@keyframes pulse {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.05);
    }

    100% {
        transform: scale(1);
    }
}

#interships .card:hover .btn {
    animation: pulse 2s infinite alternate;
}

#interships .card:hover h1 {
    color: white;
}